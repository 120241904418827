export default defineI18nConfig(() => ({
    fallbackLocale: 'de',
    datetimeFormats: {
        'de': {
            dateShort: {
                year: 'numeric', month: 'short', day: 'numeric'
            },
            dateNumeric: {
                year: 'numeric', month: '2-digit', day: '2-digit'
            },
            dateNumericWithoutYear: {
                month: '2-digit', day: '2-digit'
            },
            dateTimeShort: {
                year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'
            },
        },
        'en': {
            dateShort: {
                year: 'numeric', month: 'short', day: 'numeric'
            },
            dateTimeShort: {
                year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'
            },
        }
    }
}))